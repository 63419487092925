import React, { useState } from "react";
import "./About.css";
import Logo from "../images/Original-on-Transparent.png";
import Map from "../images/Grapevine Map.png";
import EVT from "../images/IMG-0398.png";
import ASE from "../images/ASE.png";
import SWEVT from "../images/actualSWEVT.jpg";
import TAEVT from "../images/taevt.jpeg";
import AFSMA from "../images/AFSMA-logo-150x150.jpeg";

function About() {
  const [isEnlarged, setIsEnlarged] = useState(false);

  const handleImageClick = () => {
    setIsEnlarged(!isEnlarged);
  };

  return (
    <div className={`about-wrapper ${isEnlarged ? "stack-text-on-image" : ""}`}>
      <img src={Logo} alt="Logo" className="about-logo" />
      <div className={`text-container ${isEnlarged ? "hidden" : ""}`}>
        <h1 className="about-header">About Us</h1>
        <p>
          We are passionate about the safety and reliability of your emergency
          vehicles. Our main goal at Red EVS is to minimize the out of service
          time of your fire apparatus when performing any services and/or
          repairs. The founder, Brandon Jennings, has invested his career in
          fleet maintenance since 1998, and has expanded upon that experience
          when he began specializing in fire apparatus repair in 2010. With
          dedication and years of hard work, Brandon has had the fortunate
          experience of working for a leading fire apparatus dealership,
          has worked under a fire apparatus service contractor as a lead
          emergency vehicle technician, and has also worked for various local
          municipal governments while obtaining invaluable experience, training,
          and certification. Brandon is an ASE Master Certified Truck
          Technician, EVT Certified, and a member of the TAEVT.
        </p>
        <p>
          At Red EVS, we are more than just technicians; we are your partners in
          safety. We understand the critical role fire apparatus plays in
          protecting communities, and we are committed to delivering unmatched
          expertise and top-notch service to keep your fleet in prime condition.
        </p>
        <p>
          Thank you for trusting us with your emergency vehicle needs. We look forward
          to serving you and contributing to the safety of your community.
        </p>
      </div>
      {/* <div className="service-container">
        <p>This is about our map</p>
        <img
          src={Map}
          alt="Service Map"
          className={`service-map ${isEnlarged ? "service-map-enlarged" : ""}`}
          onClick={handleImageClick}
        />
      </div> */}

      <div className="cert-container">
        <img src={EVT} alt="EVT Certification" />
        <img src={ASE} alt="ASE Certfication" />
        <img src={SWEVT} alt="SWEVT Certfication" />
        <img src={TAEVT} alt="TAEVT Certification" />
        <img src={AFSMA} alt="AFSMA Certification" />
      </div>
    </div>
  );
}

export default About;
