import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <div className="footer-container">© 2023 Red EVS | All rights reserved</div>
  );
}

export default Footer;
