import React from "react";
import "./Home.css";
import engine_out from "../images/IMG-5460.jpg";
import firetruck from "../images/vertical-firetruck.jpg";
import rods_out from "../images/image_50384641.JPG";
import { PiEngineDuotone } from "react-icons/pi";
import { GiLadder, GiTireIron } from "react-icons/gi";
import { FaArrowRightLong } from "react-icons/fa6";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import SeveralEngines from "../images/several-engines.jpg";
import { FaRegSnowflake } from "react-icons/fa6";

function Home() {
  return (
    <div>
      <div className="home">
        <div className="header">
          <div className="header-image">
            <img src={SeveralEngines} alt="Header Image" className="image" />
            <div className="overlay"></div>
          </div>
          <div className="header-content">
            <h1 className="main-header">Welcome to Red EVS</h1>
            <p className="header-caption">
              Experience Excellence in Fire Apparatus Service & Repair
            </p>
            <div className="header-button-container">
              <Link to="/about" className="learn-more-button">
                Learn More
              </Link>
              <a className="home-header-call" href="tel:9452676237">
                <strong>Call Now</strong>
              </a>
            </div>
          </div>
        </div>
        {/* Start of header */}
        {/* End of Header */}
        {/*Start of body*/}
        <body className="body-wrapper">
          <h1 className="about-home-header">
            MOBILE FIRE TRUCK SERVICE AND REPAIR
          </h1>
          <div className="why-us-wrapper">
            <div className="why-us-text">
              <h2 className="what-why" id="red-heading">Why Us?</h2>
              <p>
                We are a mobile emergency vehicle service and repair provider
                that specializes in fire apparatus. Red EVS can save you
                valuable time and money by performing many of the most common
                diagnostics and repairs at your location. This also alleviates
                the hassle of finding a repair shop to take care of your vehicle
                and the loss of valuable personnel time to drive the vehicle to
                and from the shop. Our attention to detail, experience, and
                training sets us apart from the competition.{" "}
              </p>
              <p>
                Our goal is to ensure the safety and reliability of every
                frontline, fire support, and reserve vehicle that we work on. We
                follow NFPA and D.O.T. rules, regulations, and guidelines to
                maintain compliance and to keep vehicles in proper working
                order. We work tirelessly to keep fire department personnel
                equipped with properly maintained equipment that they can trust.
              </p>
              <h2 className="what-why" id="red-heading">Mission Statement</h2>
              <p>
                To humbly and gratefully serve the men and women who risk their
                lives for others by providing exemplary mobile repair service.
                To provide trusted service that can be relied upon to minimize
                the down time of frontline and support vehicles.
              </p>
            </div>
            <div className="why-us-image">
              <img
                src={engine_out}
                alt="Driving a firetruck"
                className="why-image"
              />
            </div>
          </div>
          <div className="service-wrapper">
            <h1 className="services-header">Services</h1>
            <p className="services-header-caption">
              Here at Red EVS, we take pride in being able to repair anything
              your fleet needs.
            </p>
            <div className="highlight-image-wrapper">
              <ul className="list-of-services">
                <li className="service-item">
                  <PiEngineDuotone className="service-icon" />{" "}
                  <h2>Engine and Transmission</h2>
                </li>
                <li className="service-item">
                  <GiLadder className="service-icon" /> <h2>Aerial and Chassis</h2>
                </li>
                <li className="service-item">
                  <GiTireIron className="service-icon" />{" "}
                  <h2>Suspension and Drivetrain</h2>
                </li>
                <li className="service-item">
                  <FaRegSnowflake className="service-icon" />{" "}
                  <h2>Heating and Air Conditioning</h2>
                </li>
                <li className="service-item">
                  <Link to="/services" className="service-item">
                    <FaArrowRightLong className="service-icon" />{" "}
                    <h2 className="more-services-link">And more...</h2>
                  </Link>
                </li>
              </ul>
              <img
                src={rods_out}
                alt="man working on engine block"
                className="service-image"
              />
            </div>
          </div>
          <br />
          <h4>
            We proudly service DFW and surrounding areas. Please check our services page for
            more information!
          </h4>
          <div className="contact-wrapper">
            <div className="why-us-image">
              <img
                src={firetruck}
                alt="Driving a firetruck"
                className="firetruck-image"
              />
            </div>
            <div className="CTA">
              <h2>READY FOR UNMATCHED EXPERTISE?</h2>
              <Link to="/contact" className="home-contact-link">
                Contact Us Today!
              </Link>
            </div>
          </div>
        </body>
      </div>
    </div>
  );
}

export default Home;
