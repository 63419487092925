import React, { useState } from "react";
import "./Services.css";
import working_on_truck from "../images/IMG-7651.jpg";
import map from "../images/Grapevine Map.png";

function Services() {
  const [isMapZoomed, setIsMapZoomed] = useState(false);

  const toggleMapZoom = () => {
    setIsMapZoomed(!isMapZoomed);
  };

  return (
    <div className="services-wrapper">
      <h1 className="services-header">Services & Repair</h1>
      <div className="service-text-image-container">
        <p className="service-text-image-item" id="service-page-header-text">
          At Red EVS, we are dedicated to ensuring the reliability and safety of
          your fire apparatus. Our comprehensive services and expert repair
          solutions are designed to keep your fleet in prime condition. We
          understand the time and hassle involved in utilizing traditional
          shops, so we come to you. Explore our range of services below and
          experience the difference of having a trusted partner in fire
          apparatus maintenance.
        </p>
        <div className="service-text-image-item">
          <img
            src={working_on_truck}
            alt="firetruck maintenance"
            className="working-on-firetruck"
          />
        </div>
      </div>
      <div className="services-lists-container">
        <div className="services-list-container">
          <ul>
            <h3 className="services-lists-header">Engine Maintenance</h3>
            <li>Engine Inspection and Diagnosis</li>
            <li>Oil and Filter Changes</li>
            <li>Engine Rebuilds and Overhauls</li>
            <li>Fuel System Servicing</li>
            <li>Annual Maintenance</li>
          </ul>
        </div>
        <div className="services-list-container">
          <ul>
            <h3 className="services-lists-header">Vehicle Systems</h3>
            <li>Transmission Servicing and Repairs</li>
            <li>Drivetrain Inspections</li>
            <li>Brake System Maintenance</li>
            <li>Cooling System Repairs</li>
            <li>Steering and Suspension</li>
          </ul>
        </div>
        <div className="services-list-container">
          <ul>
            <h3 className="services-lists-header">Specialized Services</h3>
            <li>Aerial and Platform Service and Repair</li>
            <li>Fire Pump Maintenance</li>
            <li>Electrical System Diagnostics</li>
            <li>Multiplex System Repairs</li>
            <li>HVAC Service and Repair</li>
          </ul>
        </div>
      </div>
      <h4 className="service-map-header">
        We operate in and around Grapevine, Texas. Below is an approximate
        representation of our service area, but please contact us regarding your
        location.
      </h4>
      <div
        className={`map-zoom-container ${isMapZoomed ? "map-zoomed" : ""}`}
        onClick={toggleMapZoom}
      >
        <img
          src={map}
          alt="Service Map"
          className={`service-map-image ${
            isMapZoomed ? "map-zoomed-image" : ""
          }`}
        />
      </div>
      <p>Click the image to enlarge it</p>
    </div>
  );
}

export default Services;
