import React, { useRef } from "react";
import "./Contact.css";
import emailjs from "@emailjs/browser";

function Contact() {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_qq3m34n',
        'template_j9piyie',
        e.target,
        'ljLY7yvvkxymxj7xT'
      )
      .then(
        (result) => {
          alert('Message successfully sent!');

          form.current.reset();
        },
        (error) => {
          alert('Unsuccessful message, please try again');
        }
      );
  };

  return (
    <>
      <div className="contact-wrapper">
        <h1 className="contact-page-header">Contact Us</h1>
        <div className="contact-info">
          <p>
            If you have any questions or need assistance, feel free to reach out
            to us using the contact information below:
          </p>
          <ul>
            <li>
              <strong>Email:</strong>{" "}
              <a href="mailto:info.redevs@gmail.com">info.redevs@gmail.com</a>
            </li>
            <li>
              <strong>Phone:</strong>{" "}
              <a href="tel:9452676237">(945) 267-6237</a>
            </li>
          </ul>
        </div>
        <div className="contact-form">
          <h2>Send Us a Message</h2>
          <form ref={form} onSubmit={sendEmail}>
            <div className="form-group">
              <label htmlFor="name">Name:</label>
              <input type="text" id="name" name="from_name" required />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input type="email" id="email" name="from_email" required />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message:</label>
              <textarea
                id="message"
                name="message"
                rows="8"
                required
              ></textarea>
            </div>
            <button type="submit">Send Message</button>
          </form>
        </div>
      </div>
    </>
  );
}

export default Contact;
